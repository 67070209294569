import React from 'react'
import DataUpload from '../forms/DataUpload'

function Header() {
    return (
        <>
            <div className="row p-3" style={{ backgroundColor: '#0dcaf0' }}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-component-center">
                        <button className="btn btn-default text-muted p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-menu" aria-controls="offcanvas-menu">
                            <i className="bi bi-list fs-3"></i>
                        </button>
                        &emsp;
                        <a href="/" className="" style={{ textDecoration: 'none' }}>
                            <b className="fs-3 text-success">Relai</b>
                            <b className="text-primary fs-3">TZ</b>
                            <span className="text-dark">.<i className="bi bi-globe"></i>nline</span>
                        </a>
                    </div>

                    <div className="d-flex align-component-center">
                        <a className="btn btn-danger py-0" href="#">
                            <i className="bi bi-power fs-3" style={{ fontWeight: 'bold' }}></i>
                        </a>
                        <div className="dropdown d-flex align-items-center">
                            <button className="btn btn-default py-0" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-floppy fs-3"></i>
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item d-flex align-items-center" type='button' data-bs-toggle="modal" data-bs-target="#nouvellesDonneesModal" href="#">
                                        <i className="bi bi-file-earmark-excel"></i>
                                        <span>&emsp;Nouvelles Données</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <i className="bi bi-file-earmark-pdf"></i>
                                        <span>&emsp;Pièces jointes</span>
                                    </a>
                                </li>
                            </ul>

                        </div>

                        <div className="dropdown d-flex align-items-center">
                            <button className="btn btn-default py-0" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-person-circle fs-3"></i>
                            </button>
                            <ul className="dropdown-menu">
                                <li className="d-flex justify-content-center align-items-center">
                                    __user_roles
                                </li>
                                <li>
                                    <hr />
                                </li>
                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="/utilisateur/profil.html">
                                        <i className="bi bi-person"></i>
                                        <span>&emsp;Profil</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="/logout">
                                        <i className="bi bi-power"></i>
                                        <span>&emsp;Se déconnecter</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href="/detachement.html">
                                        <i className="bi bi-power"></i>
                                        <span>&emsp;Détachement</span>
                                    </a>
                                </li>
                                <li>
                                    <hr />
                                </li>
                                <li className="text-center">
                                    <span className="text-danger fw-bold">expire le :
                                        <br />
                                        __date_expiration
                                    </span><br />
                                    <a className="btn btn-outline-success py-1" href="/abonnement">Renouveler</a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
            <DataUpload />
        </>
    )
}

export default Header