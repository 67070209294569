import React from 'react'

function Footer() {
    return (
        <div className="position-relative bottom-0 container-fluid bg-dark mt-5" style={{ color: 'white', minHeight: '25vh' }}>
            <div className="container p-3 d-flex justify-content-center align-items-center">
                RelaiTZ.online&copy;  Developpé par&nbsp;
                <b>Plavui Concept</b>.
            </div>
        </div>
    )
}

export default Footer