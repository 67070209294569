import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import Table from './Components/Table';
import SimpleSearch from './Components/forms/SimpleSearch';
import Footer from './Components/pages/Footer';
import Header from './Components/pages/Header';
import Offcanvas from './Components/pages/Offcanvas';

function App() {

	const [onTableUpdate, setOnTableUpdate] = useState(false)
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState({ number: 0 });
	const [filterExecuted, setFilterExecuted] = useState({});

	const onInputChange = (value) => setFilter(filter => ({ ...filter, keys: value }))

	const titreContenu = "Recherche";



	const recherche2 = (event) => {
		event.preventDefault();
		setOnTableUpdate(true);
		recupere()
			.then((d) => {
				setFilterExecuted(filter);
				setOnTableUpdate(false);
				//console.log(d);
				setData(d);

			})
			.catch((err) => {
				setOnTableUpdate(false);
				setData([]);
				console.log(err)
			});
	}

	const recherche = (event) => {
		event.preventDefault();
		setOnTableUpdate(true);
		recupere().then((d) => {
			setFilterExecuted(filter);
			setOnTableUpdate(false);
			setData(d);
			//console.log(d);
		}).catch((error) => {
			setFilterExecuted(filter);
			setOnTableUpdate(false);
			setData([]);
			alert("Désolé : une erreur a été détectée !");
		});
	}

	const recupere = async () => {
		const reponse = await fetch("http://api.relaitz.online/recherche?" + new URLSearchParams(filter), {
			mode: 'cors',
			//method: 'GET',
			headers: { "Authorization": "Basic " + btoa("admin:supersecret") }
		});

		//console.log(reponse);
		const d = await reponse.json();
		return d;

	}

	const recupere2 = () => {
		setData([]);
		const eventSource = new EventSource("http://api.relaitz.online/recherche?" + new URLSearchParams(filter), { "Authorization": "Basic " + btoa("admin:supersecret") }
		);
		eventSource.onmessage = function (event) {
			if (event.data) {
				setFilterExecuted(filter);
				setOnTableUpdate(false);
			}

			try {
				const dataReceived = JSON.parse(event.data);
				console.log('Taille : ' + dataReceived.length)
				setData(data => ([...data, ...dataReceived]));
				console.log('Taille Totale : ' + data.length)
				if (dataReceived.length < 500) eventSource.close();
			}
			catch (SyntaxError) {
				eventSource.close();
			}
		}
	}


	return (
		<div className="App">
			<Offcanvas />

			<div className="position-relative container-fluid" style={{ minHeight: '75vh' }}>
				<Header />
				<div className="row my-4">
					<div className="col-3">
						<h5 className='text-muted text-center'>{titreContenu}</h5>
					</div>
					<div className="col-6">
						<SimpleSearch submitOperation={recherche} onSubmitState={onTableUpdate} onInputChange={onInputChange} />
					</div>
					<div className="col-3">
					</div>
				</div>

				<div className="row bg-white p-3">
					<Table updating={onTableUpdate} data={data} filter={filterExecuted} />
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default App;
