import React from 'react'
import jsPDFMethods from './PDF/jsPDFMethods';
import jsPDF from 'jspdf';

function Table({ updating, data, filter }) {

    const createTableForCompl = (complements) => {
        let table = document.createElement('table');
        let i = 0;
        complements.forEach(complement => {
            let tr = document.createElement('table');
            tr.innerHTML += '<td>${ i }</td>';
            table.append(tr);
            i++;
        });
    }
    const simpleDeclaration = (declaration) => {
        declaration = declaration.trim();
        declaration = declaration.split(' ')[0];
        return declaration;
    }

    const rechercheCompl = async (declaration) => {
        const reponse = await fetch("http://api.relaitz.online/recherche?keys=" + simpleDeclaration(declaration), {
            //mode: 'cors',
            //method: 'GET',
            headers: { "Authorization": "Basic " + btoa("admin:supersecret") }
        });
        const d = await reponse.json();
        return d;

    }

    const exportExcel = () => {
        fetch('http://api.relaitz.online/api/export?' + new URLSearchParams(filter), {
            method: 'GET',
            headers: {
                //'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `FileName.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const exportPDF = async () => {
        const jsm = new jsPDFMethods();
        jsm.printMarchaniseListe(data);
    }

    const printOnDetails = (element) => {
        var complements = [element];
        rechercheCompl(element.declaration)
            .then((cpts) => {
                complements = cpts;
            })
            .catch(() => alert("Nous n'avons pas pu charger les camions !"))
            .finally(() => {
                const jsm = new jsPDFMethods();
                jsm.printMarchaniseDetails(element, complements);

            }

            );
    }

    const len = data.length;

    if (updating) {
        return (
            <div className="container text-center p-5" >
                <h5>Recherche encours...</h5>
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        )
    }

    if (len <= 0) {
        return (
            <div className='container text-center p-5'>
                <h5>Aucun Résultat...</h5>
            </div>
        )
    }

    return (
        <div>
            <div className='row'>
                <div className='col-md-8'>
                    Total résultat : {len}
                </div>
                <div className='col-md-4'>
                    <button className='btn btn-success py-0 me-1' onClick={exportExcel}>
                        <i className='bi bi-file-excel fs-4 m-0'></i>
                    </button>
                    <button className='btn btn-danger py-0' onClick={exportPDF}>
                        <i className='bi bi-file-pdf fs-4 m-0'></i>
                    </button>
                </div>

            </div>
            <hr />
            <div className='table-responsive' style={{ backgroundColor: 'white', color: 'black', fontSize: 11 }}>
                <table id="tblResult" className='table table-hover table-sm'>
                    <thead>
                        <th>Moyen Transport</th>
                        <th>Importateur</th>
                        <th>Marchandise</th>
                        <th>Conteneur</th>
                        <th>Déclaration</th>
                        <th>Poids</th>
                        <th>Valeur</th>
                        <th>Date</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        {
                            data.map((d) => {
                                const imp = d.importateur_id ? d.importateur_id : d.importateur_saisie;
                                return (
                                    <tr key={d.id}>
                                        <td>{d.moyen_transport}</td>
                                        <td>{imp}</td>
                                        <td>{d.nature_saisie}</td>
                                        <td>{jsPDFMethods.getConteneurs(d.conteneurs)}</td>
                                        <td>{d.declaration}</td>
                                        <td>{d.quantite}</td>
                                        <td>{d.valeur}</td>
                                        <td>{jsPDFMethods.formatedDate(d.date_au)}</td>
                                        <td>
                                            <div className='d-flex'>
                                                <button className='btn btn-warning py-0' index={0} onClick={
                                                    () => {
                                                        printOnDetails(d);
                                                    }
                                                }>
                                                    <i className='bi bi-eye m-0'></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Table