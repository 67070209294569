import jsPDF from "jspdf";
import { autoTable } from 'jspdf-autotable';
import { dgda, logo, tra } from "../Images/Image";

class jsPDFMethods {

    getSimpleDeclaration(declaration) {
        declaration = declaration.trim();
        declaration = declaration.split(' ')[0];
        return declaration;
    }

    static formatedDate(date) {
        const d = new Date(date);
        return d.toLocaleDateString("fr");
    }

    static getConteneurs(conteneurs) {
        let conts = ""
        conteneurs = conteneurs.trim();
        let i = 0;
        conteneurs.split(',').forEach(cont => {
            if (i == 0) {
                conts += cont;
                i++;
            }
            else conts += '\n' + cont
        });
        return conts;
    }
    getImportateur(d) {
        const imp = d.importateur_id ? d.importateur_id : d.importateur_saisie;
        return imp;
    }

    getMarchandise(d) {
        const marc = d.nature_id ? d.nature_id : d.nature_saisie;
        return marc;
    }

    printHeader(doc) {

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width;

        doc.addImage(tra, "PNG", 20, 10, 20, 20);
        doc.addImage(dgda, "PNG", pageWidth - 40, 10, 20, 20);
        doc.addImage(logo, "PNG", Math.ceil(pageWidth * 0.4), 12, 65, 10);
        doc.line(45, 25, pageSize.width - 45, 25);
        return doc
    }

    printFooter(doc) {
        const nbr = doc.internal.getNumberOfPages();
        doc.setFontSize(9);
        let i = 0;
        for (i = 0; i < nbr; i++) {
            doc.setPage(i);
            let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
            doc.text('page: ' + pageCurrent + '/' + nbr, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 20, null, null, 'right');
        }
    }

    printMarchaniseListe(data) {
        const doc = new jsPDF({
            orientation: 'l',
            unit: 'pt',
            format: 'letter'
        });

        this.printHeader(doc);
        let positionY = 60;
        const columns = ["#", "Moyen Transport", "Importateur", "Marchandise", "Déclaration", "Poids", "Valeur"];
        var rows = [];
        let i = 1;
        let marc = "";
        let poids = 0;
        let valeur = 0;
        data.forEach(d => {
            marc = d.nature_id ? d.nature_id : d.nature_saisie;
            poids += Number(d.quantite);
            valeur += Number(d.valeur);
            rows.push(
                [
                    i,
                    d.moyen_transport + "\n" + this.constructor.getConteneurs(d.conteneurs),
                    this.getImportateur(d),
                    this.getMarchandise(d),
                    this.getSimpleDeclaration(d.declaration) + "\n" + this.constructor.formatedDate(d.date_au),
                    new Intl.NumberFormat('de-DE').format(
                        d.quantite,
                    ),
                    new Intl.NumberFormat('de-DE').format(
                        d.valeur,
                    )
                ]
            );
            i++;
        });
        rows.push(
            [
                "", 'TOTAL', '', '', '', new Intl.NumberFormat('de-DE').format(poids), new Intl.NumberFormat('de-DE').format(valeur)
            ]
        )
        doc.autoTable(columns, rows, {
            //addPageContent: pageContent,
            margin: { horizontal: 20, top: 30 },
            startY: positionY,
            styles: { overflow: 'linebreak' },
            columnStyles: {
                //id: { columnWidth: 25 },
                3: { columnWidth: 140 },
                //5: { halign: "right", fontStyle: "bold", fillColor: 40 }
                5: { halign: "right", fontStyle: "bold" },
                6: { halign: "right", fontStyle: "bold" }
                //role: { columnWidth: 15 },
                //location: { columnWidth: 30 }
            }
        });
        this.printFooter(doc);
        window.open(doc.output('bloburl'));
    }
    printMarchaniseDetails(element, complements = []) {
        console.log(complements);
        const imp = element.importateur_id ? element.importateur_id : element.importateur_saisie;
        const mar = element.nature_id ? element.nature_id : element.nature_saisie;


        const doc = new jsPDF();
        var totalPagesExp = '{total_pages_count_string}';
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width;
        const pageHeight = pageSize.height;
        const margin = 10;

        doc.setFontSize(11);
        //doc.setTextColor(7, 200, 7);

        doc.addImage(tra, "PNG", 20, 10, 20, 20);
        doc.addImage(dgda, "PNG", pageWidth - 40, 10, 20, 20);
        doc.addImage(logo, "PNG", 75, 12, 65, 10);
        let positionY = 20;

        //doc.text("RelaiTZ.Online", 105, positionY, null, null, "center");
        doc.line(45, positionY + 5, pageSize.width - 45, positionY + 5);
        positionY += 20;
        doc.setFont('helvetica');
        doc.setFont('helvetica', 'bold');
        doc.text(["Véhicule : ", "Importateur : ", "Expéditeur : ", "Marchandise : ", "Rubrique : ", "Conteneur : "], 20, positionY, { lineHeightFactor: 2 });

        doc.setFont('helvetica', 'light');
        doc.text([element.moyen_transport, imp, element.expediteur, mar, element.hs, this.constructor.getConteneurs(element.conteneurs)], 50, positionY, { lineHeightFactor: 2 });
        positionY += 42;

        doc.setFont('helvetica', 'bold');
        doc.line(margin, positionY, pageWidth - margin, positionY);
        positionY += 10;
        doc.text(["POIDS", "CIF TANZ (USD) : ", "VALEUR FACTURE : ", "FRET : "], 20, positionY, { lineHeightFactor: 1.5 });
        doc.text([
            new Intl.NumberFormat('de-DE').format(
                element.quantite,
            ),
            new Intl.NumberFormat('de-DE').format(
                element.valeur,
            ),
            new Intl.NumberFormat('de-DE').format(
                element.facture,
            ),
            new Intl.NumberFormat('de-DE').format(
                element.fret,
            )], 105, positionY, { lineHeightFactor: 1.5, align: 'right' });

        //positionY = Math.ceil(pageHeight * 0.45);
        positionY += 30;
        var columns = [];
        var rows = [];

        columns = ["N°Déclaration", "Modèle", "Date T1", "N°Facture", "Date Facture"];
        rows = [
            [element.declaration, element.modele, element.date_t1, element.numero_facture, element.date_facture]
        ];
        doc.autoTable(columns, rows, { startY: positionY });
        positionY += 15;

        columns = ["Pays O/E", "B/L", "Châssis", "Destination"];
        rows = [
            [element.pays, element.bl, element.chassis, element.destination]
        ];
        doc.autoTable(columns, rows, { startY: positionY });
        positionY += 25;

        doc.setFont(undefined, 'bold');
        doc.text("Pour la même déclaration : ", 20, positionY);
        positionY += 5;

        columns = ["#", "Véhicule", "Nature", "Poids", "Quantité", "CIF"];
        rows = [];
        let i = 1;
        let marc = "";
        complements.forEach(complement => {
            marc = complement.nature_id ? complement.nature_id : complement.nature_saisie;
            rows.push(
                [
                    i, complement.moyen_transport, marc, complement.quantite, complement.colis, complement.valeur
                ]
            );
            i++;
        });
        // Only pt supported (not mm or in)
        doc.autoTable(columns, rows, { startY: positionY });

        doc.setFont(undefined, 'bold');
        //doc.text(["Déclaration : ", " : ", "Marchandise : ", "Rubrique : "], 20, positionY, { lineHeightFactor: 2 });
        //doc.text(["Poids : ", "CIF Tanz/USD : ", "Marchandise : ", "Rubrique : "], Math.ceil(pageSize.width / 2), positionY, { lineHeightFactor: 2 });

        //doc.setTextColor(25, 255, 25);
        doc.setFont(undefined, 'regular');
        // doc.text([element.moyen_transport, "", "", element.hs], 50, positionY, { lineHeightFactor: 2, maxWidth: 40 });
        //doc.text(["Poids", "CIF Tanz/USD", "Marchandise", "Rubrique"], Math.ceil(pageSize.width / 2) + 50, positionY, { lineHeightFactor: 2 });
        //doc.save("a4.pdf");
        window.open(doc.output('bloburl'));
    }
}

export default jsPDFMethods