import React, { useState } from 'react'
import AppButton from '../AppButton'

function SimpleSearch({ submitOperation, onSubmitState, onInputChange }) {

    return (
        <>
            <form onSubmit={submitOperation}>
                <div className="input-group">
                    <input type="text" name="keys" className="form-control" placeholder="Entrer un mot clef" required
                        onChange={(event) => onInputChange(event.target.value)}
                    />
                    <AppButton className="btn btn-primary" bi="bi-search" state={onSubmitState} operate={() => {
                        document.getElementById('searchFormBtnSubmit').click()
                    }} />
                    <input id='searchFormBtnSubmit' type="submit" style={{ opacity: 0, width: 0, height: 0 }} />
                </div>
            </form>
        </>
    )
}

export default SimpleSearch