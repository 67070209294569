import React, { useState } from 'react'

function DataUpload() {
    const [file, setFile] = useState(null);
    const [isUploading, setUploading] = useState(false);

    const upload = async (event) => {
        event.preventDefault();
        if (file) {
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);

            try {
                // You can write the URL of your server or any other endpoint used for file upload
                const result = await fetch('http://localhost/relaitz.v2/api/upload/data', {
                    method: 'POST',
                    body: formData,
                });

                const data = await result.json();
                console.log(data);

            } catch (error) {
                console.error(error);
            }
        }
    };


    return (
        <div className="modal fade" id="nouvellesDonneesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="nouvellesDonneesModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable_">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="nouvellesDonneesModalLabel">Nouvelles Données</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="container-xl">
                            <div className="row">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="text-muted">Sectionnez le fichier .xlsx</h5>
                                        <hr />
                                        <form className="row" method="post" action="" encType="multipart/form-data" onSubmit={upload}>
                                            <div className="col-md-10">
                                                <div className="input-group">
                                                    <input type="file" id="file" name="file" accept=".xlsx, .xls" className="form-control" required
                                                        onChange={(event) => {
                                                            setFile(event.target.files[0]);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <button className="btn btn-primary" type="submit">Charger</button>
                                            </div>
                                        </form>
                                        {file && (
                                            <section>
                                                File details:
                                                <ul>
                                                    <li>Name: {file.name}</li>
                                                    <li>Type: {file.type}</li>
                                                    <li>Size: {file.size} bytes</li>
                                                </ul>
                                            </section>
                                        )}
                                        <br />
                                        <h4 className="text-muted">Structure</h4>
                                        <hr />
                                        <ol className="row">
                                            <div className="col-lg-4">
                                                <li>Moyen de transport</li>
                                                <li>Importateur</li>
                                                <li>Nature</li>
                                                <li>Conteneur(s)</li>
                                                <li>Type Conteneur</li>
                                                <li>Code SH</li>
                                                <li>Poids</li>
                                                <li>Valeur</li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li>Valeur Facture</li>
                                                <li>Declaration</li>
                                                <li>Bl</li>
                                                <li>Châssis</li>
                                                <li>Pays</li>
                                                <li>Observation</li>
                                                <li>Destination</li>
                                                <li>Date début</li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li>Date fin</li>
                                                <li>Colis</li>
                                                <li>Modèle</li>
                                                <li>Date T1</li>
                                                <li>Expéditeur</li>
                                                <li>Numéro Facture</li>
                                                <li>Date Facture</li>
                                                <li>Fret</li>
                                            </div>
                                        </ol>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

export default DataUpload