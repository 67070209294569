import React, { useState } from 'react'

function AppButton({ label, bi, operate, state }) {

    const trait = (event) => {
        event.preventDefault();
        operate();
    }

    const content = () => {
        return (
            <>
                {bi &&
                    <b>
                        <i className={"bi " + bi}></i>
                    </b>}
                {label}
            </>
        )
    }

    return (
        <button className='btn btn-primary' disabled={state} onClick={trait}>
            {
                state ?
                    <div className="spinner-border fs-6" role="status">
                        <span className="sr-only"></span>
                    </div>
                    : content()
            }



        </button>
    )
}

export default AppButton