import React from 'react'

function Offcanvas() {
    return (
        <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvas-menu" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <a href="/" className="offcanvas-title" style={{ textDecoration: 'none' }}>
                    <b className="fs-3 text-success">Relai</b>
                    <b className="text-primary fs-3">TZ</b>
                    <span className="text-dark">.<i className="bi bi-globe"></i>nline</span>
                </a>

                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <div className="list-group border-0">
                    <a href="/transmis" className="list-group-item list-group-item-action border-0">Transmis</a>
                    <a href="/annexes" className="list-group-item list-group-item-action border-0">Annexe</a>
                    <a href="/utilisateur/liste.html" className="list-group-item list-group-item-action border-0">Utilisateur</a>
                    <a href="/abonnement/nonvalides.html" className="list-group-item list-group-item-action border-0">Abonnement</a>
                    <a href="/outils/custex" className="list-group-item list-group-item-action border-0">Outils Custex</a>
                    <a href="/outils/datesydonia" className="list-group-item list-group-item-action border-0">Date Sydonia</a>
                </div>
            </div>
        </div>
    )
}

export default Offcanvas